import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import axios from 'axios';
import { apiurl } from '../config/config';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const Team = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const initialPermission = {
    dashboard: false,
    diagnosis: false,
    sessions: false,
    leads: false,
    orders: false,
    consultants: false,
    calendar: false,
    appointments: false,
    addteam:false,
    teams: false,
    guts:false
  };
  const [isChecked, setIsChecked] = useState(initialPermission);
  const [formData, setFormData] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [role, setRole] = useState();

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.thehealthspanco.com/api/v1/user/get/${teamId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data && response.data.data) {
          setFormData(response.data.data);
          setRole(response.data.data.role);
        } else {
          setFormData({ ...initialPermission });
        }
      })
      .catch((error) => {
        // console.log(error);
        setFormData({ ...initialPermission });
      });
  }, [teamId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleHandleChange = (name) => {
    setIsChecked((prevChecked) => ({
      ...prevChecked,
      [name]: !prevChecked[name],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = {
      ...formData,
      permissions: isChecked,
    };

    // console.log('Form Data on Submit:', formData);
    // console.log('Permissions on Submit:', isChecked);
    // console.log('Updated Data:', updatedData);


  };
  return (
    <Layout>
      <div className="w-full mt-2 h-full overflow-y-auto relative pb-32">
        <div className="flex justify-center items-center w-full mt-5">
          <NavLink to="/team">
            <div className="border border-green-800 rounded-lg text-green-800 hover:bg-green-50 cursor-pointer w-14 mx-14 text-center p-3">
              <IoArrowBack className="text-xl text-center" />
            </div>
          </NavLink>
          <div className="flex border border-green-800 justify-between w-full mx-14 p-3 rounded-md">
            <div>
              Role:
              <span className="text-xl font-semibold capitalize p-1 text-gray-600">
                {formData?.role}
              </span>
            </div>
            <div>
              Status:
              <span
                className={`text-xl font-semibold capitalize p-1 ${formData?.status === 'active'
                    ? 'text-green-500'
                    : formData?.status === 'pending'
                      ? 'text-yellow-500'
                      : 'text-red-500'
                  }`}
              >
                {formData?.status}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start w-full h-full">
          <div className="flex justify-center items-center px-10 py-3">
            <div className="w-full p-5 rounded-md">
              <div className="mt-1">
              <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-3">
                      <div className="w-1/2">
                        <label className="text-start text-sm font-medium text-green-800">
                          First Name
                        </label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="w-full outline-none border p-2 rounded-md text-gray-500"
                          name="firstname"  
                          value={formData?.firstname || ''}
                          onChange={handleInputChange}
                          disabled
                        />

                      </div>
                      <div className="w-1/2">
                        <label className="text-start text-sm font-medium text-green-800">
                          Last Name
                        </label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="w-full outline-none border p-2 rounded-md text-gray-500"
                          name="l_name"
                          value={formData?.lastname || ''}
                          onChange={handleInputChange}
                          disabled
                        />
                      </div>
                    </div>

                    <div>
                      <label className="text-start text-sm font-medium text-green-800">
                        Email
                      </label>
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="email"
                        value={formData?.email || ''}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium text-green-800">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="phone_number"
                        value={formData?.phone || ''}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium text-green-800">
                        Password
                      </label>
                      <input
                        type="password"
                        placeholder="Password"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="password"
                        value={formData?.password || ''}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium text-green-800">Age</label>
                      <input
                        type="text"
                        placeholder="Age"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="age"
                        value={formData?.age || ''}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>

                
                    <div className="mt-3">
                      <table className="w-full border-none">
                        <tbody>
                          {formData?.permissions &&
                            Object.entries(formData?.permissions).map(
                              ([key, value]) => {
                                return (
                                  <tr key={key}>
                                    <td className="text-sm font-normal border-none teamborder text-start">
                                      {key}
                                    </td>
                                    <td className="border-none teamborder">
                                      <label className="inline-flex items-center cursor-pointer">
                                        <input
                                          type="checkbox"
                                          checked={value}
                                          className="sr-only peer"
                                          onChange={() => toggleHandleChange(key)}
                                          disabled
                                        />
                                        <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-green-800 "></div>
                                      </label>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className={`bg-green-800 p-2 text-white rounded float-end mt-8`}
                        onClick={()=>navigate(`/team/${teamId}/edit`)}
                      >
                        Edit Details
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Team;
