import React, { useState } from 'react';
import Layout from '../components/layout';
import Diagnosis from './diagnosis';
import Sessions from './sessions';

// const Diagnosis = () => <div className="p-4 bg-gray-100 rounded">Diagnosis Component</div>;
// const Session = () => <div className="p-4 bg-gray-100 rounded">Session Component</div>;

const WeightQuiz = () => {
    const [activeRoute, setActiveRoute] = useState("diagnosis");

    return (
        <Layout>
            <div className="h-screen w-full p-6">
                <button 
                    className={`px-4 py-2  font-semibold ${activeRoute === 'diagnosis' ? 'bg-green-900 text-white' : 'border border-green-900 text-green-900'}  rounded`} 
                    onClick={() => setActiveRoute('diagnosis')}
                >
                    Diagnosis
                </button>
                <button 
                    className={`px-4 py-2 font-semibold mx-2 ${activeRoute === 'session' ? 'bg-green-900 text-white' :' border border-green-900 text-green-900'} hover:text-white hover:bg-green-900 rounded`} 
                    onClick={() => setActiveRoute('session')}
                >
                    Session
                </button>
                <div className=" flex-1 overflow-y-auto max-h-[500px] ">
                    {activeRoute === 'diagnosis' && <Diagnosis />}
                    {activeRoute === 'session' && <Sessions />}
                </div>
            </div>
        </Layout>
    );
};

export default WeightQuiz;
