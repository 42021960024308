import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddTeam = () => {
  const [formValues, setFormValues] = useState({
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    age: "",
    password: "",
    role: "Admin", 
    status: "active" 
  });
  
  const permission = {
    dashboard: false,
    diagnosis: false,
    sessions: false, 
    leads: false,
    orders: false,
    consultants: false,
    calendar: false,
    appointments: false,
    addteam:false,
    team: false,
    support: false
  };


  const [isChecked, setIsChecked] = useState(permission);
   const navigate = useNavigate()
  const handleRoleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const toggleHandleChange = (name) => {
    setIsChecked((prevChecked) => ({
      ...prevChecked,
      [name]: !prevChecked[name],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      email: formValues.email,
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      phone: formValues.phone,
      age: formValues.age,
      password: formValues.password,
      role: formValues.role.toLowerCase(), 
      status: formValues.status,
      permissions: isChecked
    };

    try {
      const response = await axios.post('https://api.thehealthspanco.com/api/v1/user/create', dataToSubmit, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // console.log("Response:", response.data);
      navigate('/team')
    } catch (error) {
      // console.error("Error adding team member:", error);
      alert("Failed to add team member. Please try again.");
    }
  };


  return (
    <Layout>
      <div className="w-full mt-2 h-full overflow-y-auto relative ">

        <div className='flex flex-col justify-start  w-full h-full'>

          <h2 className='text-center text-2xl font-semibold mt-4 text-green-900'>Add Teams</h2>

          <div className='flex justify-center items-center px-10 py-3'>
            <div className='w-full p-5 rounded-md'>
              <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                  <div className='flex flex-col gap-4'>
                    <div className='flex gap-3'>
                      <div className='w-1/2'>
                        <label className='text-start text-sm font-medium text-green-900'>First Name</label>
                        <input
                          type='text'
                          placeholder='First Name'
                          className='w-full outline-none border p-2 rounded-md text-gray-500'
                          name='firstname'
                          onChange={handleRoleChange}
                          value={formValues.firstname}
                        />
                      </div>
                      <div className='w-1/2'>
                        <label className='text-start text-sm font-medium text-green-900'>Last Name</label>
                        <input
                          type='text'
                          placeholder='Last Name'
                          className='w-full outline-none border p-2 rounded-md text-gray-500'
                          name='lastname'
                          onChange={handleRoleChange}
                          value={formValues.lastname}
                        />
                      </div>
                    </div>

                    <div>
                      <label className='text-start text-sm font-medium text-green-900'>Email</label>
                      <input
                        type='email'
                        placeholder='Email Address'
                        className='w-full outline-none border p-2 rounded-md text-gray-500'
                        name='email'
                        onChange={handleRoleChange}
                        value={formValues?.email}
                      />
                    </div>
                    <div>
                      <label className='text-start text-sm font-medium text-green-900'>Phone Number</label>
                      <input
                        type='text'
                        placeholder='Phone Number'
                        className='w-full outline-none border p-2 rounded-md text-gray-500'
                        name='phone'
                        onChange={handleRoleChange}
                        value={formValues.phone}
                      />
                    </div>
                    <div>
                      <label className='text-start text-sm font-medium text-green-900'>Password</label>
                      <input
                        type='password'
                        placeholder='Password'
                        className='w-full outline-none border p-2 rounded-md text-gray-500'
                        name='password'
                        onChange={handleRoleChange}
                        value={formValues?.password}
                      />
                    </div>
                    <div>
                      <label className='text-start text-sm font-medium text-green-900'>Age</label>
                      <input
                        type='text'
                        placeholder='Age'
                        className='w-full outline-none border p-2 rounded-md text-gray-500'
                        name='age'
                        onChange={handleRoleChange}
                        value={formValues?.age}
                      />
                    </div>
                    <div>
                      <select
                        name='role'
                        value={formValues.role}
                        onChange={handleRoleChange}
                        className='w-full outline-none border p-2 rounded-md text-gray-500'
                      >
                        <option value="Admin">Admin</option>
                        <option value="User">User</option>
                      </select>
                    </div>

                    {/* Permissions Table */}
                    <div className='mt-3'>
                      <table className='w-full border-none'>
                        <tbody>
                          {Object.entries(isChecked).map(([key, value]) => (
                            <tr key={key}>
                              <td className='text-sm font-normal border-none teamborder text-start'>{key}</td>
                              <td className='border-none teamborder'>
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={value}
                                    className="sr-only peer"
                                    onChange={() => toggleHandleChange(key)}
                                  />
                                  <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-green-900"></div>
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <button type='submit' className='bg-green-900 w-full p-2 text-white rounded'>Add Teams</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default AddTeam;
