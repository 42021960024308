import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { NavLink, useParams } from "react-router-dom";

import axios from "axios";
import { apiurl } from "../config/config";
import { IMG_diagnosePatient } from "../assets";
import { IoArrowBack, IoClose, IoCopyOutline } from "react-icons/io5";
import copy from "copy-to-clipboard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdDelete, MdDone } from "react-icons/md";
import SkeletonContainer from "../routes/skeleton";
import { useFormatDate } from "../hooks/useFormatDate";
import { useSelector } from "react-redux";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import moment from "moment";
const GutDiagnosisPatient = () => {
  const { patientId } = useParams();
  const [purchase, setPurchase] = useState(null);
  const [status, setStatus] = useState(null);
  const [patientData, setPatientData] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(null);
  const [order, setOrder] = useState([]);
  const [items, setItems] = useState([]);
  const [comment, setComment] = useState("");
  const [updatedComment, setUpdatedComment] = useState("");
  const [commentId, setCommentId] = useState([]);

  const userData = useSelector((state) => state?.userData);
  // console.log("UserData?????", userData);
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/gut/get/${patientId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.warn("Diagnosis data", JSON.stringify(response.data));
        if (response.data.success === true) {
          setPatientData(response.data.data);
          // console.log(
          //   "purchase value",
          //   response.data.data.orderDetails.purchase
          // );

          const fetchedPurchase = response.data.data.orderDetails.purchase;
          setPurchase(fetchedPurchase);

          setStatus(fetchedPurchase);
        }
      })
      .catch((error) => {
        // console.log("Error fetching patient data:", error);
      });
  }, [patientId]);

  const fetchOrderData = () => {
    if (!patientData?.orderDetails?.order_id) {
      // console.log("Order ID not found");
      return;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/utils/shopify/order/${patientData?.orderDetails?.order_id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.warn("order data", response.data);

        setOrder(response.data.order.order);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchOrderData();
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  const handleVisibilityToggle = () => {
    setIsVisible(!isVisible);
  };


  function TruncatedText({ text = "", wordLimit }) {
    const truncatedText = text
      ? text.split(" ").slice(0, wordLimit).join(" ")
      : "";
    return (
      <p className="text-sm mt-3">
        {truncatedText} {text && text.split(" ").length > wordLimit && "..."}
      </p>
    );
  }

  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiurl}/api/v1/comment/get/${patientId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("comment data", response.data);
        setItems(response.data.data);
      })
      .catch((error) => {
        // console.error("Error fetching comments:", error);
      });
  }, []);

  const handleComment = async (e) => {
    e.preventDefault();

    const data = JSON.stringify({
      email: userData.email,
      comment: comment,
      healthspanid: patientData._id,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/comment/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      // console.log("Comment posted successfully:", response.data.response._id);

      const newComment = {
        _id: response.data.response._id,
        name: userData.name,
        comment: comment,
        date: new Date().toISOString(),
      };

      setItems((prevItems) =>
        Array.isArray(prevItems) ? [newComment, ...prevItems] : [newComment]
      );
      setComment("");
    } catch (error) {
      // console.error("Error posting comment:", error);
    }
  };
  const handleDelete = (commentId) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/comment/delete/${commentId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("Comment deleted successfully:", response.data);
      })
      .catch((error) => {
        // console.error("Error deleting comment:", error);
      });
  };

  const HandleUpdate = (commentId) => {
    let data = JSON.stringify({
      _id: commentId,
      comment: updatedComment,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/comment/update`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response.data?.success) {
          setIsEditUpdate("");

          const updatedItems = items?.map((item) => {
            if (item._id === response.data?.update?._id) {
              return {
                ...item,
                comment: response.data.update.comment,
              };
            }
            return item;
          });

          setItems(updatedItems);

          setUpdatedComment("");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const [isEditUpdate, setIsEditUpdate] = useState("");
  console.log("updatedComment", updatedComment);
  const handleEdit = (item) => {
    setUpdatedComment(item?.comment);

    setIsEditUpdate(item._id);
    // console.log("item?.comment", item?.comment);
  };
  const FormateDate = (date) => {
    return moment(date).format(" Do YYYY, h:mm:ss a");
  };
  const handleChange = (e) => {
    const newStatus = e.target.value === "true";
    setStatus(newStatus);

    const data = JSON.stringify({
      id: patientId,
      purchase: newStatus,
    });

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: "https://api.thehealthspanco.com/api/v1/diagnose/order/update",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios
      .request(config)
      .then((response) => {
        // console.log("API Response:", JSON.stringify(response.data));
      })
      .catch((error) => {
        // console.log("API Error:", error);
      });
  };
  return (
    <Layout>
      <div className="flex flex-col w-full md:gap-5 px-2  overflow-scroll h-full pb-40">
        <div>
          <div className="border-b w-full py-1 lg:py-1 lg:pt-4  pl-2 lg:pl-4 flex md:flex-row flex-col gap-2 md:items-center justify-between">
            <div className="flex items-end gap-4">
              <NavLink to="/gutquiz">
                <div className="p-2 border border-green-800  rounded-lg text-green-800 hover:bg-green-50 cursor-pointer">
                  <IoArrowBack />{" "}
                </div>
              </NavLink>
              <h1 className="lg:text-3xl text-xl font-medium capitalize text-green-900">
                {patientData?.name}
              </h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3  gap-2">
              <p className="text-xs flex items-center gap-2 bg-green-100 border border-green-800 px-2 rounded">
                {/* {patientData?._id} */}
                Report Link
                <button>
                  <CopyToClipboard
                    // text={`https://thehealthspanco.com/pages/report?healthspanid=${patientData?._id}`}
                    text={`https://thehealthspanco.com/pages/gut-report?healthspanid=${patientData?._id}`}
                    onCopy={() => setIsCopied(true)}
                  >
                    {isCopied ? <MdDone /> : <IoCopyOutline />}
                  </CopyToClipboard>
                </button>
              </p>
              <div
                className={` flex gap-2 bg-green-100 px-2 md:px-4 py-1 md:py-2 rounded-md font-medium text-xs md:text-xs lg:text-sm border border-green-800 `}
              >
                <p className={`capitalize  text-green-900`}>email:</p>
                <p className={`text-green-700 truncate`}>
                  {patientData?.email}
                </p>
              </div>
              <div
                className={` flex gap-2 bg-green-100 px-2 md:px-4 py-1 md:py-2 rounded-md font-medium text-xs md:text-xs lg:text-sm border border-green-800 `}
              >
                <p className={`capitalize text-green-900`}>phone:</p>
                <p className={`text-green-700 `}>{patientData?.phone}</p>
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-5 h-full justify-start ">
            <div className=" w-full md:w-2/5">
              <div className="flex md:flex-row flex-col md:justify-start">
                <div className="w-56 hidden md:block  rounded-lg ">
                  <img src={IMG_diagnosePatient} className="w-full" />
                </div>

                <div className="flex flex-row md:flex-col  gap-1 md:gap-5  pt-3 md:pt-10">
                  {patientData?.bmi?.range && (
                    <Range
                      label="bmi"
                      title={patientData?.bmi?.range}
                      className="bg-red-100 border border-red-300 md:w-auto w-full"
                      textColor="text-red-500"
                      labelColor="bg-red-500"
                    />
                  )}
                  {/* <div className="relative"> */}
                  {patientData?.height && (
                    <Range
                      label="Height"
                      title={patientData?.height}
                      className="bg-green-100 border border-green-300 md:w-auto w-full "
                      textColor="text-green-700"
                      labelColor="bg-green-500"
                    />
                  )}
                  {/* <span className="h-full w-2 bg-red-500 absolute"></span> */}
                  {/* </div> */}
                  {patientData?.weight && (
                    <Range
                      label="Weight"
                      title={patientData?.weight}
                      className="bg-blue-100 border border-blue-300 md:w-auto w-full"
                      textColor="text-blue-800"
                      labelColor="bg-blue-500"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 px-4 md:pl-0 flex flex-col gap-1.5 md:gap-2">
              <Frame className="bg-green-50 border border-green-800 text-green-800 w-full ">
                <Information label="healthspanid" title={patientData?._id} />
              </Frame>
              <div className="flex  gap-3 ">
                <InformationContainer>
                  <Information label="age" title={patientData?.age} />
                  <Information label="gender" title={patientData?.gender} />
                  <Information label="bmr" title={patientData?.bmr} />

                  {/* <GreyContainer>
                    <Information
                      label="health goals"
                      title={patientData?.health_goals?.map((item) => (
                        <p className="border-b mr-2">{item},</p>
                      ))}
                    />
                  </GreyContainer> */}

                  {/* <Information
                    label="gut symtoms"
                    title={
                      patientData?.gut_symptom
                        ? patientData?.gut_symptom
                        : "NaN"
                    }
                  /> */}
                  <Information label="exercise" title={patientData?.exercise} />
                  <Information label="range" title={patientData?.range} />

                  {/* <Information
                    label="medical condition"
                    title={patientData?.medical_condition}
                  /> */}
                </InformationContainer>
                <InformationContainer>
                  {/* <Information
                    label="ideal Weight"
                    title={patientData?.idealWeight}
                  />
                  <Information
                    label="Weight to loose"
                    title={patientData?.weightToLose}
                  />
                  <Information
                    label="weight loose duration"
                    title={patientData?.weightLossDuration}
                  />
                  <Information
                    label="ideal waist size"
                    title={patientData?.idealWaistSize}
                  />
                  <Information
                    label="daily calories intake"
                    title={patientData?.dailyCalorieIntake}
                  /> */}
                  <Information
                    label="phyiscal activity"
                    title={patientData?.phyiscal_activity}
                  />
                  {/* <Information
                    label="eating_habits"
                    title={patientData?.eating_habits}
                  /> */}
                  <Information
                    label="pass stoll"
                    title={patientData?.pass_stoll}
                  />
                  <Information
                    label="gut symtoms"
                    title={patientData?.bothered}
                  />
                  {/* <GreyContainer>
                    <Information
                      label="allergic"
                      title={patientData?.allergic?.map((item) => (
                        <p>{item},</p>
                      ))}
                    />
                  </GreyContainer> */}
                  <Information
                    label="medication"
                    title={patientData?.medication}
                  />
                  {/* <Information label="sleep" title={patientData?.sleep} /> */}
                  <Information
                    label="history of obesity"
                    title={patientData?.history_of_obesity}
                  />
                </InformationContainer>
              </div>

              <div className="flex flex-col gap-3 pt-3">
                {patientData?.root_cause && (
                  <Frame
                    title="root cause"
                    className="bg-zinc-50 border border-green-800 w-full text-green-950 "
                  >
                    {patientData?.root_cause?.map((item) => (
                      <label>{item}, </label>
                    ))}
                  </Frame>
                )}

                <Frame
                  title="Concerns"
                  className="bg-slate-50 border border-green-800 text-green-900 w-full "
                >
                  {patientData?.concerns?.join(", ")}
                </Frame>
                <div className="w-full mt-3 md:mt-0  rounded-lg bg-zinc-50 border border-green-800 p-3">
                  <Information
                    label="diagonse date"
                    title={useFormatDate(patientData?.gut_date)}
                    // className="grid-cols-1"
                    // labelClassName="col-span-2"
                    // titleClassName="col-span-2 "
                  />
                  {/* <Information
                    label="diagnosed conditions"
                    title={patientData?.diagnosed_conditions}
                  className="grid-cols-1"
                  labelClassName="col-span-2"
                  titleClassName="col-span-2 "
                  /> */}
                  <Information
                    label="Score"
                    title={patientData?.score}
                    // className="grid-cols-1"
                    // labelClassName="col-span-2"
                    // titleClassName="col-span-2 "
                  />
                  <Information
                    label="current score"
                    title={patientData?.currentscore}
                    // className="grid-cols-1"
                    // labelClassName="col-span-2"
                    // titleClassName="col-span-2 "
                  />
                </div>
                <div className="w-full mt-3">
                  <select
                    className={`w-full text-gray-600 border border-green-900 py-1 px-3 capitalize rounded-md cursor-pointer shadow-sm outline-none ${
                      status === true
                        ? "text-green-500"
                        : status === false
                        ? "text-red-500"
                        : ""
                    }`}
                    value={
                      status === true ? "true" : status === false ? "false" : ""
                    }
                    onChange={handleChange}
                    disabled={purchase === null}
                  >
                    <option value="" className="text-gray-600" disabled>
                      ---Select Purchase---
                    </option>
                    <option value="true" className="text-green-500">
                      Purchase True
                    </option>
                    <option value="false" className="text-red-500">
                      Purchase False
                    </option>
                  </select>
                </div>
                {patientData?.orderDetails?.order_id && (
                  <>
                    {patientData?.orderDetails?.purchase &&
                    patientData?.orderDetails?.purchase === true ? (
                      <button
                        className="bg-black text-white px-7 py-3 rounded-md mt-2"
                        onClick={handleVisibilityToggle}
                      >
                        {isVisible ? "Hide Orders" : "View Orders"}
                      </button>
                    ) : (
                      <>No Order Found</>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {!patientData?.orderDetails?.order_id && (
          <Frame className="bg-red-50 border border-red-300 text-red-500 w-full my-5 ">
            <p>Order ID not found for this user.</p>
          </Frame>
        )}

        {isVisible && order && (
          <>
            <div className="bg-gray-100 min-h-screen p-7">
              <h1 className="text-2xl font-bold text-gray-900 mb-6">
                Order Details
              </h1>
              <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <div className="mb-4 border-b border-gray-200 pb-6">
                  <div className="flex justify-between">
                    <h2 className="text-xl font-semibold text-gray-800">
                      Order #{order?.order_number}
                    </h2>
                    <div className="flex items-center gap-6">
                      <p className="text-sm text-gray-900 mt-1">
                        Status:{" "}
                        <span className="text-gray-500 ">
                          {order?.financial_status}
                        </span>{" "}
                      </p>
                      <p className="text-sm text-gray-900 ">
                        Order ID:
                        <span className="text-gray-500 ">{order?.id}</span>{" "}
                      </p>
                    </div>
                  </div>

                  <p className="text-sm text-gray-900 mt-1">
                    Confirmation Number:{" "}
                    <span className="text-gray-500 p-1">
                      {order?.confirmation_number}
                    </span>{" "}
                  </p>

                  <div className="">
                    <p className="text-sm text-gray-900 mt-1">
                      Created At:{" "}
                      <span className="text-gray-500 p-1">
                        {new Date(order?.created_at).toLocaleString()}
                      </span>
                    </p>
                    <p className="text-sm text-gray-900 mt-1">
                      Updated At:{" "}
                      <span className="text-gray-500 p-1">
                        {" "}
                        {new Date(order?.updated_at).toLocaleString()}
                      </span>
                    </p>
                    <p className="text-sm text-gray-900 mt-1">
                      Processed At:
                      <span className="text-gray-500 p-1">
                        {new Date(order?.processed_at).toLocaleString()}
                      </span>{" "}
                    </p>
                  </div>
                  <div className="float-end mb-2">
                    {" "}
                    <p className=" text-gray-900 font-semibold text-md">
                      Total Price:
                      <span className="text-gray-500 text-sm p-1">
                        {order?.total_price_set?.shop_money.amount}{" "}
                        {order?.total_price_set?.shop_money?.currency_code}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between mb-4 border-b border-gray-200 pb-4">
                  <div className="">
                    <h3 className="text-lg font-medium text-gray-700">
                      Billing Address
                    </h3>
                    <p className="text-gray-600">
                      {order?.billing_address?.first_name}{" "}
                      {order.billing_address?.last_name}
                    </p>
                    <p className="text-gray-600">
                      {order?.billing_address?.address1},{" "}
                      {order?.billing_address?.city}
                    </p>
                    <p className="text-gray-600">
                      {order.billing_address?.province},{" "}
                      {order.billing_address?.country}
                    </p>
                    <p className="text-gray-900">
                      ZIP Code:
                      <span className="text-gray-500 p-1">
                        {order?.billing_address?.zip}
                      </span>
                    </p>
                    <p className="text-gray-900">
                      Phone:
                      <span className="text-gray-500 p-1">
                        {order?.billing_address?.phone}
                      </span>{" "}
                    </p>
                  </div>
                  <div className="">
                    <h3 className="text-lg font-medium text-gray-700">
                      Shipping Address
                    </h3>
                    <p className="text-gray-600">
                      {order.shipping_address.first_name}{" "}
                      {order.shipping_address.last_name}
                    </p>
                    <p className="text-gray-600">
                      {order.shipping_address.address1},{" "}
                      {order.shipping_address.city}
                    </p>
                    <p className="text-gray-600">
                      {order.shipping_address.province},{" "}
                      {order.shipping_address.country}
                    </p>
                    <p className="text-gray-900">
                      ZIP Code:
                      <span className="text-gray-500 p-1 mt-1">
                        {order.shipping_address.zip}
                      </span>{" "}
                    </p>
                    <p className="text-gray-900">
                      Phone:
                      <span className="text-gray-500 p-1 mt-1">
                        {order.shipping_address.phone}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between mb-4 border-b border-gray-200 pb-4">
                  <div className="">
                    <h3 className="text-lg font-medium text-gray-700">
                      Shipping Lines
                    </h3>
                    {order?.shipping_lines?.map((line, idx) => (
                      <div key={idx} className="mb-2">
                        <p className="font-semibold text-gray-800">
                          {line.title}
                        </p>
                        <p className="text-gray-900">
                          Price:{" "}
                          <span className="text-gray-500 p-1">
                            {line.price_set.shop_money.amount}{" "}
                            {line.price_set.shop_money.currency_code}
                          </span>
                        </p>
                        {line.discounted_price && (
                          <p className="text-gray-900">
                            Discounted Price:
                            <span className="text-gray-500 p-1">
                              {line.discounted_price_set.shop_money.amount}{" "}
                              {
                                line.discounted_price_set.shop_money
                                  .currency_code
                              }
                            </span>{" "}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="">
                    <h3 className="text-lg font-medium text-gray-700">
                      Order Summary
                    </h3>
                    <p className="text-gray-900">
                      Subtotal:{" "}
                      <span className="text-gray-500 p-1">
                        {order.current_subtotal_price_set.shop_money.amount}{" "}
                        {
                          order.current_subtotal_price_set.shop_money
                            .currency_code
                        }
                      </span>
                    </p>
                    <p className="text-gray-900">
                      Total Tax:
                      <span className="text-gray-500 p-1">
                        {order.total_tax_set.shop_money.amount}{" "}
                        {order.total_tax_set.shop_money.currency_code}
                      </span>{" "}
                    </p>
                    <p className="text-gray-900">
                      Shipping Price:
                      <span className="text-gray-500 p-1">
                        {order.total_shipping_price_set.shop_money.amount}{" "}
                        {
                          order.total_shipping_price_set.shop_money
                            .currency_code
                        }
                      </span>{" "}
                    </p>
                    <p className="text-gray-900">
                      Total Line Items Price:
                      <span className="text-gray-500 p-1">
                        {order.total_line_items_price_set.shop_money.amount}{" "}
                        {
                          order.total_line_items_price_set.shop_money
                            .currency_code
                        }
                      </span>{" "}
                    </p>
                    <p className="text-gray-900">
                      Total Discounts:{" "}
                      <span className="text-gray-500 p-1">
                        {" "}
                        {order.total_discounts_set.shop_money.amount}{" "}
                        {order.total_discounts_set.shop_money.currency_code}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="border-b">
                  <h3 className="text-lg font-medium text-gray-700">
                    Line Items
                  </h3>

                  <div className="mb-2">
                    <table className="border-collapse w-full relative table-auto overflow-auto">
                      <thead className="bg-zinc-50 border-y text-zinc-700 lg:text-base text-xs sticky top-0">
                        <tr>
                          <th
                            className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                          >
                            Item Id
                          </th>
                          <th
                            className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                          >
                            Item Name
                          </th>
                          <th
                            className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                          >
                            Item Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-zinc-500 px-10 text-start">
                        {order?.line_items?.map((line, idx) => (
                          <tr
                            key={idx}
                            className="hover:text-black cursor-pointer hover:bg-zinc-50/50"
                          >
                            <td
                              className=" py-2 sm:py-3 "
                              style={{ paddingLeft: 10 }}
                            >
                              {line?.id}
                            </td>
                            <td
                              className=" py-2 sm:py-3 "
                              style={{ paddingLeft: 10 }}
                            >
                              {line?.title}
                            </td>
                            <td
                              className=" py-2 sm:py-3 "
                              style={{ paddingLeft: 10 }}
                            >
                              {line.price_set.shop_money.amount}{" "}
                              {line.price_set.shop_money.currency_code}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mb-4 mt-2">
                  <h3 className="text-lg font-medium text-gray-700">
                    Note Attributes
                  </h3>
                  {order?.note_attributes?.map((note, idx) => (
                    <div key={idx} className="mb-2">
                      <p className="font-semibold text-gray-800">{note.name}</p>
                      <p className="text-gray-600">{note.value}</p>
                    </div>
                  ))}
                </div>

                <div className="mb-4">
                  <h3 className="text-lg font-medium text-gray-700">
                    Other Details
                  </h3>
                  <p className="text-gray-600">
                    Customer Email: {order.contact_email}
                  </p>
                  <p className="text-gray-600">Tags: {order.tags}</p>
                  <p className="text-gray-600">
                    Source Name: {order.source_name}
                  </p>
                  <p className="text-gray-600">
                    Order Status URL:{" "}
                    <a
                      href={order.order_status_url}
                      className="text-blue-500 hover:underline"
                    >
                      {order.order_status_url}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-full  mx-auto border border-green-800 py-6 px-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold border-b border-b-green-900 pb-3 mb-5">
            Comment
          </h2>

          <div>
            <form
              onSubmit={handleComment}
              className="flex flex-col sm:flex-row w-full items-center space-y-4 sm:space-y-0 sm:space-x-4"
            >
              <div className="flex items-center justify-between w-full border border-gray-200 rounded-lg px-4 py-2">
                <input
                  type="text"
                  placeholder="Add Your Comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="w-full bg-transparent outline-none text-sm sm:text-base p-2"
                />
              </div>

              <button
                type="submit"
                disabled={!comment.trim()}
                className={`w-full sm:w-auto py-3 px-6 sm:px-9 rounded-lg font-semibold ${
                  comment.trim()
                    ? "bg-green-800 text-white"
                    : "bg-gray-400 text-gray-200 cursor-not-allowed"
                }`}
              >
                Send
              </button>
            </form>
          </div>

          <div className="mt-8 px-6 max-h-64 overflow-y-scroll">
            {Array.isArray(items) && items.length > 0 ? (
              items?.map((item, index) => (
                <div
                  key={index}
                  className="mt-4 border border-gray-200 shadow-sm rounded-lg p-4 bg-gray-50 flex justify-around"
                >
                  <div className="w-4/5">
                    <h3 className="font-semibold">{item?.consultBy?.name}</h3>
                    {isEditUpdate === item?._id ? (
                      <input
                        className="w-full outline-none bg-gray-50"
                        value={updatedComment}
                        onChange={(e) => setUpdatedComment(e.target.value)}
                      />
                    ) : (
                      <TruncatedText text={item?.comment} wordLimit={20} />
                    )}
                  </div>
                  <div className="w-1/5">
                    <div className="flex justify-end gap-2 text-xl">
                      {isEditUpdate === item?._id ? (
                        <>
                          <FaCheck
                            onClick={() => {
                              HandleUpdate(item._id);
                              setUpdatedComment(item?.comment);
                            }}
                          />
                          <IoClose onClick={() => setIsEditUpdate("")} />
                        </>
                      ) : (
                        <>
                          <CiEdit onClick={() => handleEdit(item)} />
                          <MdDelete onClick={() => handleDelete(item._id)} />
                        </>
                      )}
                    </div>
                    <div className="flex justify-end mt-2">
                      <p className="text-sm text-gray-500">
                        {FormateDate(item.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No comments available.</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GutDiagnosisPatient;

export const Heading = ({ children }) => (
  <div className="bg-zinc-50 text-base font-medium px-5 py-2 capitalize">
    {children}
  </div>
);

export const PatientInformation = ({ children, className }) => {
  return (
    <div
      className={` bg-zinc-50/50 rounded-xl  p-4 flex flex-col items-center w-full  ${className}`}
    >
      {children}
    </div>
  );
};

export const Information = ({
  label,
  title,
  className,
  labelClassName,
  titleClassName,
}) => (
  <div
    className={`grid grid-cols-2 gap-2  font-medium text-xs md:text-sm lg:text-sm  ${className} `}
  >
    <p className={`capitalize text-green-900 ${labelClassName}`}>{label}</p>
    <p
      className={`text-zinc-500   ${titleClassName ? titleClassName : "col-span-1"
        }`}
    >
      {title ? title : <SkeletonContainer data="fetching..." />}
    </p>
  </div>
);

export const Range = ({ label, title, className, labelColor, textColor }) => (
  <div
    className={`flex flex-col items-center  font-medium text-xs md:text-sm lg:text-base overflow-hidden rounded-md ${className}`}
  >
    <p
      className={`capitalize text-xs ${labelColor} text-white w-full text-center py-1`}
    >
      {label}
    </p>

    <p
      className={`text-base font-bold md:font-semibold md:text-xl lg:text-2xl py-2 px-7  ${textColor} `}
    >
      {title}
    </p>
  </div>
);

export const Frame = ({ title, children, className, textColor }) => (
  <div
    className={`gap-5 font-medium text-xs md:text-sm lg:text-base  px-3 py-3 rounded-md ${className}`}
  >
    <p className="capitalize">{title}</p>
    <p className={`text- font-normal px-5 ${textColor}  `}>{children}</p>
  </div>
);

export const InformationContainer = ({ children }) => (
  <div className="w-full flex flex-col gap-1 shadow-sm border border-green-800  rounded-lg p-2 px-4">
    {children}
  </div>
);

export const GreyContainer = ({ children }) => (
  <div className="bg-zinc-50 border border-zinc-100 rounded-lg p-1 ">
    {children}
  </div>
);

export const BlueContainer = ({ children }) => (
  <div className="bg-blue-50/60 border border-blue-100 rounded-lg p-1 ">
    {children}
  </div>
);
