import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  profieCardHandler,
  setingsDropdownHandler,
  sideNavHandler,
  teamStaffDropDownHandler,
} from "../redux/slices/handler";
import Logo from "./logo";
import { Icon } from "../utils/icons";
import { BiSolidHelpCircle } from "react-icons/bi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { useUser } from "../context/appcontext";

export const menus = [
  { icon: Icon.dashboard, title: "dashboard" },
  // { icon: Icon.daignosis, title: "diagnosis" },
  // { icon: Icon.appointment, title: "sessions" },
  { icon: Icon.daignosis, title: "weightquiz" },
  { icon: Icon.guts, title: "gutquiz" },
  { icon: Icon.wellness, title: "wellnessquiz" },
  // { icon: Icon.lead, title: "leads" },
  // { icon: Icon.orders, title: "orders" },
  { icon: Icon.consultant, title: "consultants" },
  { icon: Icon.appointment, title: "appointments" },
];

export const settings = [
  { icon: <BiSolidHelpCircle />, title: "Help" },
  { icon: <MdOutlineSupportAgent />, title: "Support" },
];

export const teamStaffs = [
  { icon: <BiSolidHelpCircle />, title: "Add team" },
  { icon: <MdOutlineSupportAgent />, title: "Team" },
];

const SideNav = () => {
  const { userData } = useUser();
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState({});
  const [role, setRole] = useState(null);
  const handler = useSelector((state) => state.handler);

  useEffect(() => {
    if (userData) {
      setPermissions(userData?.permissions);
      setRole(userData?.role);
    }
  }, [userData]);

  return (
    <div className="bg-green-50 h-full w-full flex flex-col justify-between border-r z-50 relative lg:overflow-y-scroll">
      <div>
        {/* Header */}
        <div className="py-4 px-7 h-10 lg:h-14 border-zinc-200">
          <NavLink to="/">
            <Logo className="h-5 lg:h-8" />
          </NavLink>
        </div>

        <div className="px-2 flex flex-col gap-3 py-4 text-xs lg:text-sm">
          {/* Search */}
          {/* <div className="bg-green-50 border border-green-950 flex items-center py-2 px-1.5 rounded-lg">
            <IoSearchOutline className="text-lg text-zinc-600" />
            <input
              placeholder="Search"
              className="bg-transparent outline-none"
            />
          </div> */}

          {/* Menus */}
          <div className="text-zinc-600 px-0.5 flex flex-col gap-1">
            {menus.map((item, index) => {
              const isAllowed = permissions?.[item.title];
              return (
                <MenuList
                  key={index}
                  item={item}
                  isAllowed={isAllowed}
                />
              );
            })}
          </div>

          {/* Teams & Staffs */}
          {(role === "admin" || role === "master") && (
            <div className="flex flex-col gap-2 px-0.5">
              <div className="flex items-center justify-between text-green-950 px-2">
                <button
                  className="flex items-center gap-2 font-semibold"
                  onClick={() => dispatch(teamStaffDropDownHandler())}
                >
                  <FaChevronDown
                    className={`lg:text-sm transition duration-200 ${
                      handler?.isTeamsStaff ? "rotate-180" : ""
                    }`}
                  />
                  Teams & Staffs
                </button>
              </div>
              <div
                className={`text-zinc-600 flex flex-col transition duration-300 px-2 ${
                  handler?.isTeamsStaff
                    ? "h-auto opacity-100 block"
                    : "h-0 opacity-0 hidden"
                }`}
              >
                {teamStaffs.map((item, index) => (
                  <MenuList item={item} key={index} isAllowed={true} />
                ))}
              </div>
            </div>
          )}
          
          {/* Settings */}
          {/* <div className="flex flex-col gap-2 px-0.5">
            <div className="flex items-center justify-between text-zinc-500 px-2">
              <button
                className="flex items-center gap-2 font-semibold"
                onClick={() => dispatch(setingsDropdownHandler())}
              >
                <FaChevronDown
                  className={`lg:text-sm transition duration-200 ${
                    handler?.isSettings ? "rotate-180" : ""
                  }`}
                />
                Settings
              </button>
            </div>
            <div
              className={`text-zinc-600 flex flex-col transition duration-300 px-2 ${
                handler?.isSettings
                  ? "h-auto opacity-100 block"
                  : "h-0 opacity-0 hidden"
              }`}
            >
              {settings.map((item, index) => (
                <MenuList item={item} key={index} />
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SideNav;

const MenuList = ({ item, isAllowed }) => {
  const dispatch = useDispatch();

  const SideNavHandler = () => {
    dispatch(sideNavHandler(false));
    dispatch(profieCardHandler(false));
  };

  const convertTitleToLowerCase = (title) => {
    return title.toLowerCase().replace(/\s+/g, "");
  };

  return (
    <NavLink
      to={
        item.title === "dashboard"
          ? "/"
          : `/${convertTitleToLowerCase(item.title)}`
      }
      onClick={(e) => {
        if (isAllowed) {
          SideNavHandler();
        } else {
          e.preventDefault();
        }
      }}
      className={({ isActive }) =>
        `flex capitalize items-center gap-2 font-medium py-1.5 px-2 border border-transparent 
        rounded-lg cursor-pointer 
        ${isAllowed ? "hover:transition duration-200 ease-in-out hover:border-green-900" : "text-green-900 pointer-events-none"}
        ${isActive ? "text-white font-semibold bg-green-900" : "text-green-900 "}`
      }
    >
      <span className="text-base">{item.icon}</span>
      {item.title}
    </NavLink>
  );
};
