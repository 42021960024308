import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import axios from "axios";
import { apiurl } from "../config/config.js";
import { RiDeleteBinLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import moment from "moment";

import ConfirmModal from "./confirmModal";
import { MdDelete } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
const GutSession = () => {
  const [sessionData, setSessionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 50;
  const [filteredData, setFilteredData] = useState([]);
  const [wantDelete, setWantDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiurl}/api/v1/gut/session/getall?page=${currentPage}&limit=${itemsPerPage}`
        );
        if (response.data.success) {
          setSessionData(response.data.data);
          setFilteredData(response.data.data);
          setTotalPages(response.data.pagination.totalPages);
        }
      } catch (error) {
        // console.error(error);
      }
    };

    fetchData();
  }, [currentPage]);

  const handleSelectRow = (sessionId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(sessionId)
        ? prevSelectedRows.filter((id) => id !== sessionId)
        : [...prevSelectedRows, sessionId]
    );
  };

  const handleDelete = (id) => {
    setWantDelete(true);
    setDeleteId(id);
  };

  const confirmDelete = async () => {
    try {
      if (deleteId) {
        await axios.delete(`${apiurl}/api/v1/gut/session/delete`, {
          data: { sessionId: deleteId },
        });

        setSessionData((prevData) =>
          prevData.filter((item) => item.sessionId !== deleteId)
        );
        setFilteredData((prevData) =>
          prevData.filter((item) => item.sessionId !== deleteId)
        );
      } else if (selectedRows.length > 0) {
        await axios.delete(`${apiurl}/api/v1/gut/session/deleteall`, {
          data: { sessionIds: selectedRows },
        });

        setSessionData((prevData) =>
          prevData.filter((item) => !selectedRows.includes(item.sessionId))
        );
        setFilteredData((prevData) =>
          prevData.filter((item) => !selectedRows.includes(item.sessionId))
        );
        setSelectedRows([]);
      }
      setWantDelete(false);
      setDeleteId(null);
    } catch (error) {
      // console.error("Error deleting session(s):", error);
    }
  };

  const cancelDelete = () => {
    setWantDelete(false);
    setDeleteId(null);
  };

  // const startIndex = currentPage * itemsPerPage;
  // const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  // const handlePageClick = (event) => {
  //   setCurrentPage(event.selected);
  // };

  const formatDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };

  const handleSearch = async (e) => {
    const newSearch = e.target.value;
    setSearch(newSearch);

    if (newSearch === "") {
      setFilteredData(sessionData);
    } else {
      try {
        const response = await axios.get(
          `${apiurl}/api/v1/gut/session/search/${newSearch}`
        );
        if (response.data.success) {
          setFilteredData(response.data.data || []);
        }
      } catch (error) {
        // console.error("Search API Error:", error);
      }
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="h-full overflow-y-auto text-xs md:text-sm lg:text-base  mt-2 relative pb-52 lg:pb-20">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full mt-5">
        {/* Search Input */}
        <div className="bg-white border border-green-800 flex items-center py-2 px-4 rounded-lg w-full shadow-sm">
          <div className="flex items-center gap-3 w-full">
            <IoSearchOutline className="text-lg text-zinc-600" />
            <input
              placeholder="Search"
              value={search}
              onChange={handleSearch}
              className="bg-transparent outline-none w-full placeholder:text-zinc-400 text-sm"
            />
          </div>
        </div>

        {/* Bulk delete button */}
        {selectedRows.length > 0 && (
          <div className="flex justify-end sm:justify-start w-full sm:w-auto mt-5 sm:mt-0">
            <button
              onClick={() => setWantDelete(true)}
              className="p-2 px-6 bg-green-800 text-white rounded-md text-sm font-medium hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
            >
              Delete Selected ({selectedRows.length})
            </button>
          </div>
        )}
      </div>

      <table className="border-collapse w-full table-auto border mt-5">
        <thead className="bg-green-900 border text-white lg:text-base text-xs sticky top-0 z-50">
          <tr>
            <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">Select</th>
            <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">Name</th>
            <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">Email</th>
            <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">Phone No.</th>
            <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
              Age
            </th>
            <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
              Gender
            </th>
            <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
              Height
            </th>
            <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
              Date
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="text-zinc-500 text-center">
          {filteredData.length > 0
            ? filteredData.map((item, index) => (
                <tr
                  key={index}
                  className="hover:text-black cursor-pointer hover:bg-green-50/50 capitalize"
                >
                  <td className="px-4 py-2 sm:py-3 sm:px-6 text-center">
                    <input
                      type="checkbox"
                      className="scale-125"
                      checked={selectedRows.includes(item?.sessionId)}
                      onChange={() => handleSelectRow(item?.sessionId)}
                    />
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:px-6 capitalize">
                    {item?.data?.name}
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:px-6 capitalize">
                    {item?.data?.email}
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:px-6">
                    {item?.data?.phone}
                  </td>
                  <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                    {item?.data?.age}
                  </td>
                  <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 capitalize">
                    {item?.data?.gender}
                  </td>
                  <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                    {item?.data?.height}
                  </td>
                  <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                    {formatDate(item?.createdAt)}
                  </td>
                  <td className="text-center flex">
                    <MdDelete
                      className="text-black m-auto hover:bg-red-50 border border-transparent hover:border-red-300 p-2 text-4xl rounded-lg"
                      onClick={() => handleDelete(item?.sessionId)}
                    />
                  </td>
                </tr>
              ))
            : [...Array(5)].map((_, key) => (
                <tr key={key}>
                  {[...Array(9)].map((__, i) => (
                    <td key={i} className="px-4 py-2 sm:py-3 sm:px-6">
                      <SkeletonContainer data="loading..." />
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>

      {/* Confirm Modal */}
      {wantDelete && (
        <ConfirmModal
          message={
            selectedRows.length > 0
              ? `Are you sure you want to delete ${selectedRows.length} user(s)?`
              : "Are you sure you want to delete this user?"
          }
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}

      {/* Pagination */}
      <div className=" px-10 fixed bottom-0 flex justify-center md:left-7 left-0 bg-white w-full h-16">
        <div className="flex justify-center items-center my-4">
          <button
            className="px-6 py-2 mx-1 text-sm md:text-base rounded-lg text-white bg-green-900 font-semibold"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="px-4 text-green-900 font-bold whitespace-nowrap">{`${currentPage} / ${totalPages}`}</span>
          <button
            className="px-6 py-2 mx-1 text-sm md:text-base  rounded-lg text-white bg-green-900  font-semibold"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default GutSession;
