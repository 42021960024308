import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { apiurl } from "../config/config";
import { IMG_diagnosePatient } from "../assets";
import { IoArrowBack, IoClose, IoCopyOutline } from "react-icons/io5";
import copy from "copy-to-clipboard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdDelete, MdDone } from "react-icons/md";
import SkeletonContainer from "../routes/skeleton";
import { useFormatDate } from "../hooks/useFormatDate";
import { useDispatch, useSelector } from "react-redux";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import moment from "moment";
import ConfirmModal from "./confirmModal";
import ExportDataHandler from "../components/exportDataHandler";
import { GoDownload } from "react-icons/go";

const WellnessQuizReport = () => {
  const { patientId } = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [purchase, setPurchase] = useState(null);
  const [status, setStatus] = useState(null);
  const [patientData, setPatientData] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [wellnessData, setwellnessData] = useState([]);
  const [date, setDate] = useState({ from: "", till: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("50");
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [customerId, setCustomerId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [createdDate, setCreatedDate] = "";
  const navigate = useNavigate();

  const userData = useSelector((state) => state?.userData);
  console.log("UserData?????", userData);
  console.log("startDate", startDate);
  console.log("endDate", endDate);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/wellness/quiz/get/${patientId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setPatientData(response.data.data);
          setCustomerId(response.data.data.customerId);
          setCreatedDate(response.data.data.createdAt);
          const fetchedPurchase = response.data.data.orderDetails.purchase;
          setPurchase(fetchedPurchase);

          setStatus(fetchedPurchase);
        }
      })
      .catch((error) => {});
  }, [patientId]);

  const getCurrentDate = () => {
    return new Date().toISOString().split("T")[0];
  };

  // Update useEffect for setting initial dates
  useEffect(() => {
    const today = getCurrentDate();
    const formattedCreatedDate = createdDate
      ? new Date(createdDate).toISOString().split("T")[0]
      : today;

    setStartDate(formattedCreatedDate); // From Date = createdDate or today
    setEndDate(today); // To Date = Current Date initially
  }, [createdDate]);

  const FormateDate = (date) => {
    return moment(date).format(" Do YYYY, h:mm:ss a");
  };

  console.log("currentPage", currentPage);
  useEffect(() => {
    const fetchData = async () => {
      if (!customerId || !startDate || !endDate) return;
      let data = JSON.stringify({
        customerId: customerId,
        startdate: startDate,
        enddate: endDate,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.thehealthspanco.com/api/v1/wellness/quiz/get/date",
        headers: {
          "Content-Type": "application/json",
          Cookie:
            "_shopify_y=8141EC42-3d80-4AC8-9c94-3bab47a1c1ad; _tracking_consent=%7B%22con%22%3A%7B%22CMP%22%3A%7B%22a%22%3A%22%22%2C%22m%22%3A%22%22%2C%22p%22%3A%22%22%2C%22s%22%3A%22%22%7D%7D%2C%22v%22%3A%222.1%22%2C%22region%22%3A%22INUP%22%2C%22reg%22%3A%22%22%2C%22purposes%22%3A%7B%22a%22%3Atrue%2C%22p%22%3Atrue%2C%22m%22%3Atrue%2C%22t%22%3Atrue%7D%2C%22display_banner%22%3Afalse%2C%22sale_of_data_region%22%3Afalse%2C%22consent_id%22%3A%22899A5EBE-e758-4455-a4b1-e744026882f6%22%7D",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          console.log("wellnessreport data", response.data);
          setwellnessData(response.data.entries);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, [customerId, startDate, endDate]);

  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  useEffect(() => {
    if (date.from && date.till) {
      FilterHandler();
    }
  }, [date]);

  const FilterHandler = () => {
    if (wellnessData.length > 0) {
      const fromDate = new Date(date.from);
      const tillDate = new Date(date.till);

      const filtered = wellnessData.filter((item) => {
        const wellnessData = new Date(
          moment(item?.diagonse_date).format("YYYY-MM-DD")
        );
        return wellnessData >= fromDate && wellnessData <= tillDate;
      });

      setFilteredData(filtered);
      setCurrentPage(0);
    }
  };
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      setShowModal(true);
    }
  };

  const confirmDelete = () => {
    if (userIdToDelete) {
      axios
        .post(
          `https://api.thehealthspanco.com/api/v1/wellness/quiz/delete/${userIdToDelete}`
        )
        .then(() => {
          setwellnessData((prevData) =>
            prevData.filter((item) => item._id !== userIdToDelete)
          );
          setFilteredData((prevData) =>
            prevData.filter((item) => item._id !== userIdToDelete)
          );
          setShowModal(false);
        })
        .catch((error) => {
          // console.log(error);
          setShowModal(false);
        });
    } else {
      selectedRows.forEach((userId) => {
        axios
          .post(
            `https://api.thehealthspanco.com/api/v1/diagnose/delete/${userId}`
          )
          .then(() => {
            setwellnessData((prevData) =>
              prevData.filter((item) => item._id !== userId)
            );
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== userId)
            );
          })
          .catch((error) => console.log(error));
      });
      setSelectedRows([]);
      setShowModal(false);
    }
  };

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  console.log("wellnessData", wellnessData);
  function calculateStreak(data) {
    console.log("streak data:", data);
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);

    const entries = data
      ?.map((entry) => {
        const date = new Date(entry.date);
        date.setUTCHours(0, 0, 0, 0);
        return { date, supplement: entry?.result?.supplement };
      })
      ?.sort((a, b) => b.date - a.date);

    if (!entries || entries.length === 0) return 0;

    let streak = 0;
    let previousDate = currentDate;

    for (let i = 0; i < entries.length; i++) {
      const currentEntry = entries[i];
      const diffInDays =
        (previousDate - currentEntry.date) / (1000 * 60 * 60 * 24);

      const supplementValue = currentEntry.supplement?.toLowerCase();

      console.log("Supplement:", supplementValue);

      if (diffInDays === 0 && supplementValue === "yes") {
        streak = 1;
      } else if (diffInDays === 1 && supplementValue === "yes") {
        streak++;
        previousDate = currentEntry.date;
      } else {
        break;
      }
    }

    return streak;
  }

  const streak = calculateStreak(wellnessData);
  console.log("streak", streak);
  function calculateAdherenceRate(data) {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);

    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setUTCDate(thirtyDaysAgo.getUTCDate() - 29);

    const relevantEntries = data
      ?.map((entry) => {
        const date = new Date(entry.date);
        date.setUTCHours(0, 0, 0, 0);
        return { date, supplement: entry?.result?.supplement };
      })
      .filter(
        (entry) => entry.date >= thirtyDaysAgo && entry.date <= currentDate
      );

    console.log("relevantEntries", relevantEntries);

    if (!relevantEntries || relevantEntries.length === 0) return 0;

    const yesDays = new Set(
      relevantEntries
        .filter((entry) => entry.supplement?.toLowerCase() === "yes")
        .map((entry) => entry.date.getTime())
    );

    console.log("yesDays", yesDays.size);

    const totalDays = 30;
    const adherenceRate = (yesDays.size / totalDays) * 100;

    return Math.round(adherenceRate);
  }
  const adherence = calculateAdherenceRate(wellnessData);
  console.log("streak", streak);

  const handleDateChange = (e, setter) => {
    const selectedDate = e.target.value;
    const minDate = createdDate
      ? new Date(createdDate).toISOString().split("T")[0]
      : "";
    const maxDate = getCurrentDate();

    // Only update if the selected date is valid
    if ((!minDate || selectedDate >= minDate) && selectedDate <= maxDate) {
      setter(selectedDate);
    }
  };
  return (
    <Layout>
      <div className="flex flex-col w-full md:gap-5   px-2  overflow-scroll h-full pb-40">
        <div>
          <div className="border-b w-full py-1 lg:py-1 lg:pt-4  pl-2 lg:pl-4 flex md:flex-row flex-col gap-2 md:items-center justify-between">
            <div className="flex items-end gap-4">
              <NavLink to="/wellnessquiz">
                <div className="p-2 border border-green-800  rounded-lg text-green-800 hover:bg-green-50 cursor-pointer">
                  <IoArrowBack />{" "}
                </div>
              </NavLink>
              <h1 className="lg:text-3xl text-xl font-medium capitalize text-green-900">
                {patientData?.name}
              </h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3  gap-2">
              <p className="text-xs flex items-center gap-2 bg-green-100 border border-green-800 px-2 rounded">
                {/* {patientData?._id} */}
                Report Link
                <button>
                  <CopyToClipboard
                    text={`https://thehealthspanco.com/pages/report?healthspanid=${patientData?._id}`}
                    onCopy={() => setIsCopied(true)}
                  >
                    {isCopied ? <MdDone /> : <IoCopyOutline />}
                  </CopyToClipboard>
                </button>
              </p>
              <div
                className={` flex gap-2 bg-green-100 px-2 md:px-4 py-1 md:py-2 rounded-md font-medium text-xs md:text-xs lg:text-sm border border-green-800 `}
              >
                <p className={`capitalize  text-green-900`}>email:</p>
                <p className={`text-green-700 truncate`}>
                  {patientData?.email}
                </p>
              </div>
              <div
                className={` flex gap-2 bg-green-100 px-2 md:px-4 py-1 md:py-2 rounded-md font-medium text-xs md:text-xs lg:text-sm border border-green-800 `}
              >
                <p className={`capitalize text-green-900`}>phone:</p>
                <p className={`text-green-700`}>{patientData?.phone}</p>
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-5  justify-end ">
            <div className="mt-5 px-4 md:pl-0 flex flex-col gap-1.5 md:gap-2">
              <Frame className="bg-green-50 border border-green-800 text-green-800 w-full ">
                <Information label="healthspanid" title={patientData?._id} />
              </Frame>
            </div>
          </div>
          <div className="w-full  h-full">
            <div className="my-3 flex flex-col md:flex-row items-center  relative gap-3">
              {/* Delete Button */}
              {selectedRows.length > 0 && (
                <div className="flex items-center w-full sm:w-auto mt-0 md:mt-3">
                  <button
                    className="text-white bg-green-800 px-4 py-1 rounded cursor-pointer w-full sm:w-auto"
                    onClick={handleDeleteSelected}
                  >
                    Delete
                  </button>
                </div>
              )}

              {/* Confirmation Modal */}
              {showModal && (
                <ConfirmModal
                  message={
                    selectedRows.length > 0
                      ? `Are you sure you want to delete ${selectedRows.length} user(s)?`
                      : "Are you sure you want to delete this user?"
                  }
                  onConfirm={confirmDelete}
                  onCancel={cancelDelete}
                />
              )}

              {/* Search & Filters */}
              <div className="w-full flex flex-col md:flex-row justify-end items-center gap-3 mt-3">
                <input
                  type="date"
                  className="border border-green-900 p-1 rounded text-green-900"
                  value={startDate}
                  min={
                    createdDate
                      ? new Date(createdDate).toISOString().split("T")[0]
                      : undefined
                  }
                  max={getCurrentDate()}
                  onChange={(e) => handleDateChange(e, setStartDate)}
                />
                <input
                  type="date"
                  className="border border-green-900 p-1 rounded text-green-900"
                  value={endDate}
                  min={
                    createdDate
                      ? new Date(createdDate).toISOString().split("T")[0]
                      : undefined
                  }
                  max={getCurrentDate()}
                  onChange={(e) => handleDateChange(e, setEndDate)}
                />
              </div>
            </div>

            <div className="h-full overflow-scroll text-xs md:text-sm lg:text-base   pb-56 md:pb-48 lg:pb-20">
              <table className="border-collapse w-full relative table-auto overflow-auto border ">
                <thead className="bg-green-900 border-y text-white lg:text-base text-xs  top-0 z-50">
                  <tr>
                    <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center max-w-xs">
                      Select
                    </th>
                    <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                      Date
                    </th>
                    <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                      Complete
                    </th>
                    <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                      Count Strik
                    </th>
                    <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                      Adherence
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {wellnessData && wellnessData.length > 0 ? (
                    wellnessData.map((user) => (
                      <tr key={user._id}>
                        <td className="px-2 py-1 sm:px-4 md:px-6 text-center max-w-xs">
                          <input
                            type="checkbox"
                            className="scale-125"
                            checked={selectedRows.includes(user._id)}
                            onChange={() => handleSelectRow(user._id)}
                          />
                        </td>
                        <td
                          className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer capitalize"
                          onClick={() =>
                            navigate(`/wellnessquiz/${user._id}`, {
                              state: { patient: user },
                            })
                          }
                        >
                          {FormateDate(user?.date)}
                        </td>
                        <td
                          className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer capitalize"
                          onClick={() =>
                            navigate(`/diagnosis/${user._id}`, {
                              state: { patient: user },
                            })
                          }
                        >
                          {user?.complete ? "True" : "False"}
                        </td>
                        <td className="px-2 py-1 sm:px-4 md:px-6 text-center capitalize">
                          {streak}
                        </td>
                        <td className="px-2 py-1 sm:px-4 md:px-6 text-center capitalize">
                          {adherence}
                        </td>
                        <td className="px-2 py-1 sm:px-2 md:px-2 text-center capitalize flex items-center justify-center gap-2">
                          <button
                            className="border border-green-900 rounded-lg text-green-900 px-4 py-1 hover:bg-green-900 hover:text-white"
                            onClick={() => setIsOpen(true)}
                          >
                            View More
                          </button>
                        </td>
                        {isOpen && (
                          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-[600px]">
                              <div className="flex justify-between items-center border-b border-green-900 ">
                                <h2 className="text-xl font-bold mb-4 text-green-900 ">
                                  {user.name}
                                </h2>
                                {/* Close Button */}
                                <button
                                  className=" px-4 py-1 border border-green-900 text-green-900 hover:bg-green-900 hover:text-white font-semibold rounded-lg"
                                  onClick={() => setIsOpen(false)}
                                >
                                  Close
                                </button>
                              </div>

                              <div className="border border-green-900 rounded p-2 mt-4">
                                <div className="flex justify-around">
                                  <div>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Email :
                                      </span>{" "}
                                      {user.email}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Phone.no. :
                                      </span>{" "}
                                      {user.phone}{" "}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Date :
                                      </span>{" "}
                                      {FormateDate(user.date)}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Complete :
                                      </span>{" "}
                                      {user?.complete ? "True" : "False"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border border-green-900 rounded p-2 mt-2">
                                <div className="flex justify-around">
                                  <div>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Supplement :
                                      </span>{" "}
                                      {user?.result?.supplement}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Missed Reason :
                                      </span>{" "}
                                      {user?.result?.missedReason}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Reminder Suggestion :
                                      </span>{" "}
                                      {user?.result?.reminderSuggestion}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Reminder Time :
                                      </span>{" "}
                                      {user?.result?.reminderTime}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Custom Reminder Time :
                                      </span>{" "}
                                      {user?.result.customReminderTime}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Rreorder Prompt :
                                      </span>{" "}
                                      {user?.result?.reorderPrompt}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Motivation Check :
                                      </span>{" "}
                                      {user?.result.motivationCheck}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Results Tip :
                                      </span>{" "}
                                      {user?.result?.resultsTip}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Show Education :
                                      </span>{" "}
                                      {user?.result?.showEducation}{" "}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Feeling :
                                      </span>{" "}
                                      {user?.result?.feeling}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Low Energy Reason :
                                      </span>{" "}
                                      {user?.result?.lowEnergyReason}{" "}
                                    </p>

                                    <p className="text-green-900">
                                      <span className="text-green-900 font-medium text-sm">
                                        Mental Wellness :
                                      </span>{" "}
                                      {user?.result?.mentalWellness}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Physical Wellness :
                                      </span>{" "}
                                      {user?.result?.physicalWellness}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Sleep Check :
                                      </span>{" "}
                                      {user?.result?.sleepCheck}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Caffeine Check :
                                      </span>{" "}
                                      {user?.result?.caffeineCheck}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium text-sm">
                                        Activity Prompt :
                                      </span>{" "}
                                      {user?.result?.activityPrompt}{" "}
                                    </p>
                                    <p className="text-green-900 text-sm">
                                      <span className="text-green-900 font-medium">
                                        Activity :
                                      </span>{" "}
                                      {user?.result?.activity}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        className="text-center text-green-900 font-bold py-4"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* <div className=" px-10 fixed bottom-0 flex justify-center md:left-7 left-0 bg-white w-full h-16">
              <div className="flex justify-center items-center my-4">
                <button
                  className="px-6 py-2 mx-1 text-sm md:text-base rounded-lg text-white bg-green-900 font-semibold"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="px-4 text-green-900 font-bold whitespace-nowrap">{`${currentPage} / ${totalPages}`}</span>
                <button
                  className="px-6 py-2 mx-1 text-sm md:text-base  rounded-lg text-white bg-green-900  font-semibold"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WellnessQuizReport;

export const Heading = ({ children }) => (
  <div className="bg-zinc-50 text-base font-medium px-5 py-2 capitalize">
    {children}
  </div>
);

export const PatientInformation = ({ children, className }) => {
  return (
    <div
      className={` bg-zinc-50/50 rounded-xl  p-4 flex flex-col items-center w-full  ${className}`}
    >
      {children}
    </div>
  );
};

export const Information = ({
  label,
  title,
  className,
  labelClassName,
  titleClassName,
}) => (
  <div
    className={`grid grid-cols-2 gap-2  font-medium text-xs md:text-sm lg:text-sm  ${className} `}
  >
    <p className={`capitalize text-green-900 ${labelClassName}`}>{label}</p>
    <p
      className={`text-zinc-500   ${
        titleClassName ? titleClassName : "col-span-1"
      }`}
    >
      {title ? title : <SkeletonContainer data="fetching..." />}
    </p>
  </div>
);

export const Range = ({ label, title, className, labelColor, textColor }) => (
  <div
    className={`flex flex-col items-center  font-medium text-xs md:text-sm lg:text-base overflow-hidden rounded-md ${className}`}
  >
    <p
      className={`capitalize text-xs ${labelColor} text-white w-full text-center py-1`}
    >
      {label}
    </p>

    <p
      className={`text-base font-bold md:font-semibold md:text-xl lg:text-2xl py-2 px-7  ${textColor} `}
    >
      {title}
    </p>
  </div>
);

export const Frame = ({ title, children, className, textColor }) => (
  <div
    className={`gap-5 font-medium text-xs md:text-sm lg:text-base  px-3 py-3 rounded-md ${className}`}
  >
    <p className="capitalize">{title}</p>
    <p className={`text- font-normal px-5 ${textColor}  `}>{children}</p>
  </div>
);

export const InformationContainer = ({ children }) => (
  <div className="w-full flex flex-col gap-1 shadow-sm border border-green-800  rounded-lg p-2 px-4">
    {children}
  </div>
);

// export const GreyContainer = ({ children }) => (
//   <div className="bg-zinc-50 border border-zinc-100 rounded-lg p-1 ">
//     {children}
//   </div>
// );

// export const BlueContainer = ({ children }) => (
//   <div className="bg-blue-50/60 border border-blue-100 rounded-lg p-1 ">
//     {children}
//   </div>
// );
