import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Loader from "../components/loader";

const TeamDetail = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const initialPermission = {
    dashboard: false,
    diagnosis: false,
    sessions: false,
    leads: false,
    orders: false,
    consultants: false,
    calendar: false,
    appointments: false,
    addteam:false,
    teams: false,
    guts:false
  };

  const [isChecked, setIsChecked] = useState(initialPermission);
  const [formData, setFormData] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.thehealthspanco.com/api/v1/user/get/${teamId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data && response.data.data) {
          setFormData(response.data.data);
          setIsChecked(response.data.data.permissions || initialPermission);
        } else {
          setFormData({ ...initialPermission });
        }
      })
      .catch((error) => {
        // console.log(error);
        setFormData({ ...initialPermission });
      });
  }, [teamId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsFormChanged(true);
  };

  const toggleHandleChange = (permission) => {
    setIsChecked((prevChecked) => ({
      ...prevChecked,
      [permission]: !prevChecked[permission],
    }));
    setIsFormChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedData = {
      ...formData,
      userId: formData._id,
      permissions: isChecked,
    };

    try {
      // console.log("updated data", updatedData);

      const config = {
        method: "post",
        url: `https://api.thehealthspanco.com/api/v1/user/updateuser`,
        headers: {
          "Content-Type": "application/json",
        },
        data: updatedData,
      };

      const response = await axios.request(config);
      // console.log("Response from server:", response.data);
      if (response) {
        setLoading(false);
      }
      setIsFormChanged(false);
      navigate("/team");
    } catch (error) {
      // console.error("Error updating data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="w-full mt-2 h-full overflow-y-auto relative pb-32">
        <div className="w-full mt-5">
          <NavLink to="/team">
            <div className="border border-green-800 rounded-lg text-green-800 hover:bg-green-50 cursor-pointer w-14 mx-14 text-center p-3">
              <IoArrowBack className="text-xl text-center" />
            </div>
          </NavLink>
        </div>
        <div className="flex flex-col justify-start w-full h-full">
          <div className="flex justify-center items-center px-10 py-3">
            <div className="w-full p-5 rounded-md">
              <div className="mt-1">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col sm:flex-row sm:justify-between w-full rounded-md gap-4">
                      <div>
                        <select
                          className="border py-2 w-full sm:w-40 capitalize rounded-md cursor-pointer shadow-sm text-gray-500 outline-none"
                          value={formData?.role}
                          name="role"
                          onChange={handleInputChange}
                        >
                          <option value="user" className="text-black">
                            user
                          </option>
                          <option value="admin" className="text-black">
                            admin
                          </option>
                          <option
                            value="master"
                            className="text-white bg-gray-300"
                            disabled
                          >
                            master
                          </option>
                        </select>
                      </div>
                      <div>
                        <select
                          className={`border py-2 w-full sm:w-40 capitalize rounded-md cursor-pointer shadow-sm text-gray-500 outline-none ${
                            formData?.status === "active"
                              ? "text-green-500 border-green-500"
                              : formData?.status === "pending"
                              ? "text-yellow-500 border-yellow-500"
                              : "text-red-500 border-red-500"
                          }`}
                          value={formData?.status}
                          name="status"
                          onChange={handleInputChange}
                        >
                          <option value="draft" className="text-black">
                            draft
                          </option>
                          <option value="active" className="text-black">
                            active
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="flex gap-3">
                      <div className="w-1/2">
                        <label className="text-start text-sm font-medium text-green-800">
                          First Name
                        </label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="w-full outline-none border p-2 rounded-md text-gray-500"
                          name="firstname"
                          value={formData?.firstname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="text-start text-sm font-medium text-green-800">
                          Last Name
                        </label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="w-full outline-none border p-2 rounded-md text-gray-500"
                          name="lastname"
                          value={formData?.lastname}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="text-start text-sm font-medium text-green-800">
                        Email
                      </label>
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="email"
                        value={formData?.email}
                        disabled
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium text-green-800">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="phone"
                        value={formData?.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium text-green-800">
                        Password
                      </label>
                      <input
                        type="password"
                        placeholder="Password"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="password"
                        value={formData?.password}
                        disabled
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium text-green-800">
                        Age
                      </label>
                      <input
                        type="text"
                        placeholder="Age"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="age"
                        value={formData?.age}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Permissions Table */}
                    <div className="mt-3">
                      <table className="w-full border-none">
                        <tbody>
                          {Object.entries(isChecked).map(([key, value]) => (
                            <tr key={key}>
                              <td className="text-sm font-normal border-none teamborder text-start">
                                {key}
                              </td>
                              <td className="border-none teamborder">
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={value}
                                    className="sr-only peer"
                                    onChange={() => toggleHandleChange(key)}
                                  />
                                  <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-green-800 "></div>
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className={`bg-green-800 w-full h-10 relative p-2 text-white rounded flex justify-center items-center ${
                          !isFormChanged ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={!isFormChanged || loading === true}
                      >
                        {loading ? (
                          <Loader className="h-16 w-16  " />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TeamDetail;
