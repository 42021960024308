// import React, { useEffect, useState } from "react";
// import Layout from "../components/layout";
// import axios from "axios";
// import { apiurl } from "../config/config";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { fetchData } from "../redux/slices/diagnosPatient";
// import SkeletonContainer from "../routes/skeleton";
// import { useFormatOnlyDate } from "../hooks/useFormatDate";
// import moment from "moment";
// import { GoDownload } from "react-icons/go";
// import ExportDataHandler from "../components/exportDataHandler";
// import ReactPaginate from "react-paginate";
// import { FaPencilAlt } from "react-icons/fa";
// import { MdDelete } from "react-icons/md";
// import ConfirmModal from "./confirmModal";
// import { IoSearchOutline } from "react-icons/io5";
// const Gut = () => {
//   const [gutsData, setgutsData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [date, setDate] = useState({ from: "", till: "" });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState("50");
//   const [showModal, setShowModal] = useState(false);
//   const [userIdToDelete, setUserIdToDelete] = useState(null);
//   const [search, setSearch] = useState();
//   const [totalPages, setTotalPages] = useState(0);
//   console.log("currentPage", currentPage);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const config = {
//           method: "get",
//           maxBodyLength: Infinity,
//           url: `${apiurl}/api/v1/gut/getall?page=${currentPage}&limit=${itemsPerPage}`,
//           headers: {},
//         };
//         const response = await axios.request(config);
//         if (response.data.success) {
//           const data = response.data.data || [];
//           setgutsData(data);
//           setFilteredData(data);
//           setTotalPages(response.data.pagination.totalPages);
//         }
//       } catch (error) {
//         // console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [currentPage]);
//   // console.log("filterData", filteredData);

//   const getCurrentDate = () => {
//     const currentDate = new Date();
//     const day = currentDate.getDate();
//     const month = currentDate.getMonth() + 1;
//     const year = currentDate.getFullYear();
//     const padZero = (num) => (num < 10 ? `0${num}` : num);
//     let formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
//     return formattedDate.split("-").reverse().join("-");
//   };

//   const FormateDate = (date) => {
//     return moment(date).format("MMMM Do YYYY");
//   };

//   const dateHandler = (e) => {
//     const { name, value } = e.target;
//     setDate({ ...date, [name]: value });
//   };
//   useEffect(() => {
//     if (date.from && date.till) {
//       FilterHandler();
//     }
//   }, [date]);

//   const FilterHandler = () => {
//     if (gutsData.length > 0) {
//       const fromDate = new Date(date.from);
//       const tillDate = new Date(date.till);

//       const filtered = gutsData.filter((item) => {
//         const gutsData = new Date(
//           moment(item?.diagonse_date).format("YYYY-MM-DD")
//         );
//         return gutsData >= fromDate && gutsData <= tillDate;
//       });

//       setFilteredData(filtered);
//       setCurrentPage(0);
//     }
//   };
 

//   // const confirmDelete = () => {
//   //   if (userIdToDelete) {
//   //     axios
//   //       .post(
//   //         `https://api.thehealthspanco.com/api/v1/diagnose/delete/${userIdToDelete}`
//   //       )
//   //       .then(() => {
//   //         setDiagnosData((prevData) =>
//   //           prevData.filter((item) => item._id !== userIdToDelete)
//   //         );
//   //         setFilteredData((prevData) =>
//   //           prevData.filter((item) => item._id !== userIdToDelete)
//   //         );
//   //         setShowModal(false);
//   //       })
//   //       .catch((error) => {
//   //         console.log(error);
//   //         setShowModal(false);
//   //       });
//   //   } else {
//   //     selectedRows.forEach((userId) => {
//   //       axios
//   //         .post(
//   //           `https://api.thehealthspanco.com/api/v1/diagnose/delete/${userId}`
//   //         )
//   //         .then(() => {
//   //           setDiagnosData((prevData) =>
//   //             prevData.filter((item) => item._id !== userId)
//   //           );
//   //           setFilteredData((prevData) =>
//   //             prevData.filter((item) => item._id !== userId)
//   //           );
//   //         })
//   //         .catch((error) => console.log(error));
//   //     });
//   //     setSelectedRows([]);
//   //     setShowModal(false);
//   //   }
//   // };
//   const confirmDelete = () => {
//     let config = {
//       method: "delete",
//       maxBodyLength: Infinity,
//       url: `https://api.thehealthspanco.com/api/v1/gut/delete/${userIdToDelete}`,
//       headers: {},
//     };

//     axios
//       .request(config)
//       .then((response) => {
//         // console.log("Delete successful:", response.data);

//         // Filter out the deleted user from the local frontend state
//         setFilteredData((prevData) =>
//           prevData.filter((user) => user._id !== userIdToDelete)
//         );

//         // Hide the confirmation modal
//         setShowModal(false);
//       })
//       .catch((error) => {
//         // console.error("Error deleting the user:", error);
//       });
//   };

//   const handleDelete = (userId) => {
//     setUserIdToDelete(userId);
//     setShowModal(true);
//   };

//   const cancelDelete = () => {
//     setShowModal(false);
//   };

//   // Handle page click

//   const ExportPayload = (data) => {
//     const payload = Array.isArray(data) ? data : [data];
//     return payload.map((item) => {
//       return {
//         _id: item._id,
//         name: item.name,
//         email: item.email,
//         phone: item.phone,
//         date: item.date,
//         age: item.age,
//         gender: item.gender,
//         weight: item.weight,
//         height: item.height,
//         health_goals: Array.isArray(item.health_goals)
//           ? item.health_goals.join(", ")
//           : "",
//         bmi_category: item.bmi?.category || "",
//         bmi_range: item.bmi?.range || "",
//         gut_symptom: Array.isArray(item.gut_symptom)
//           ? item.gut_symptom.join(", ")
//           : "",
//         exercise: item.exercise || "",
//         phyiscal_activity: item.phyiscal_activity || "",
//         medical_condition: item.medical_condition || "",
//         root_cause: Array.isArray(item.root_cause)
//           ? item.root_cause.join(", ")
//           : "",
//         bmr: item.bmr || "",
//         idealWeight: item.idealWeight || "",
//         weightToLose: item.weightToLose || "",
//         weightLossDuration: item.weightLossDuration || "",
//         idealWaistSize: item.idealWaistSize || "",
//         dailyCalorieIntake: item.dailyCalorieIntake || "",
//         diagnosed_conditions: Array.isArray(item.diagnosed_conditions)
//           ? item.diagnosed_conditions.join(", ")
//           : "",
//         eating_habits: item.eating_habits || "",
//         allergic: Array.isArray(item.allergic) ? item.allergic.join(", ") : "",
//         medication: item.medication || "",
//         sleep: item.sleep || "",
//         diagonse_date: item.diagonse_date || "",
//         description: item.description || "",
//         createdAt: item.createdAt || "",
//         updatedAt: item.updatedAt || "",
//       };
//     });
//   };

//   // const handleSearch = (e) => {
//   //   const newSearch = e.target.value;
//   //   setSearch(newSearch);

//   //   if (newSearch === "") {
//   //     setFilteredData(diagnosData);
//   //   } else {

//   //     axios
//   //       .get(`https://api.thehealthspanco.com/api/v1/diagnose/get/search/${newSearch}`)
//   //       .then((response) => {
//   //         if (response.data.success === true) {

//   //           setFilteredData(response.data.data || []);
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Search API Error:", error);
//   //       });
//   //   }
//   // };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) setCurrentPage(currentPage + 1);
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) setCurrentPage(currentPage - 1);
//   };
//   const handleDeleteSelected = () => {
//     if (selectedRows.length > 0) {
//       setShowModal(true); 
//     }
//   };
//   const handleSelectRow = (id) => {
//     setSelectedRows((prevSelectedRows) =>
//       prevSelectedRows.includes(id)
//         ? prevSelectedRows.filter((rowId) => rowId !== id)
//         : [...prevSelectedRows, id]
//     );
//   };
//   return (
//     <div className="w-full  h-full">
//       <div className="my-3 flex flex-col md:flex-row items-center  relative gap-3">
//         {/* Delete Button */}
//         {selectedRows.length > 0 && (
//           <div className="flex items-center w-full sm:w-auto mt-0 md:mt-3">
//             <button
//               className="text-white bg-green-800 px-4 py-1 rounded cursor-pointer w-full sm:w-auto"
//               onClick={handleDeleteSelected}
//             >
//               Delete
//             </button>
//           </div>
//         )}

//         {/* Confirmation Modal */}
//         {showModal && (
//           <ConfirmModal
//             message={
//               selectedRows.length > 0
//                 ? `Are you sure you want to delete ${selectedRows.length} user(s)?`
//                 : "Are you sure you want to delete this user?"
//             }
//             onConfirm={confirmDelete}
//             onCancel={cancelDelete}
//           />
//         )}

//         {/* Search & Filters */}
//         <div className="w-full flex flex-col md:flex-row justify-end items-center gap-3 mt-3">
//           {/* Search Bar */}
//           {/* <div className="bg-white border border-green-800 flex items-center py-2 px-2 rounded-lg w-full">
//             <IoSearchOutline className="text-lg text-zinc-600" />
//             <input
//               placeholder="Search"
//               value={search}
//               className="bg-transparent outline-none w-full px-2"
//               onChange={handleSearch}
//             />
//           </div> */}

//           {/* Date Filters & Export Button */}
//           <div className="flex flex-col sm:flex-row md:items-center gap-3 w-full md:w-auto">
//             <input
//               placeholder="From"
//               name="from"
//               type="date"
//               max={getCurrentDate()}
//               className="outline-none border border-green-800 rounded-lg text-sm px-3 py-2 w-full sm:w-auto"
//               onChange={dateHandler}
//               value={date.from}
//             />
//             <input
//               placeholder="Till"
//               name="till"
//               type="date"
//               min={date.from}
//               max={getCurrentDate()}
//               className="outline-none border border-green-800 rounded-lg text-sm px-3 py-2 w-full sm:w-auto"
//               onChange={dateHandler}
//               value={date.till}
//             />
//             <button
//               className={`border p-2 px-4 text-center rounded-md w-full sm:w-auto ${
//                 date.from && date.till && filteredData.length > 0
//                   ? "bg-green-50 border-green-800 text-green-800"
//                   : "border-green-800 text-green-800"
//               }`}
//               onClick={() => ExportDataHandler(ExportPayload(filteredData))}
//               disabled={!(date.from && date.till && filteredData.length > 0)}
//             >
//               <GoDownload className="text-xl" />
//             </button>
//           </div>
//         </div>
//       </div>
//       <div className="h-full overflow-scroll text-xs md:text-sm lg:text-base  mt-6 pb-56 md:pb-48 lg:pb-20">
//         <table className="border-collapse w-full relative table-auto overflow-auto border ">
//           <thead className="bg-green-900 border-y text-white lg:text-base text-xs  top-0 z-50">
//             <tr>
//               <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                   Select
//                 </th>
//               <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 Name
//               </th>
//               <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 Email
//               </th>
//               <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 Phone
//               </th>
//               <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 Date
//               </th>
//               <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 B.S.T
//               </th>
//               <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 B.M.I
//               </th>
//               <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 Purchase
//               </th>
//               <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
//                 Action
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData && filteredData.length > 0
//               ? filteredData.map(
//                   (user) => (
//                     console.log("date", user),
//                     (
//                       <tr key={user._id}>
//                         <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
//                         <input
//                           type="checkbox"
//                           className="scale-125"
//                           checked={selectedRows.includes(user._id)}
//                           onChange={() => handleSelectRow(user._id)}
//                         />
//                       </td>
//                         <td className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer capitalize"
//                          onClick={() =>
//                             navigate(`/gutdiagnosis/${user._id}`, {
//                               state: { patient: user },
//                             })
//                           }>
//                           {user?.name}
//                         </td>
//                         <td className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer capitalize"
//                          onClick={() =>
//                             navigate(`/gutdiagnosis/${user._id}`, {
//                               state: { patient: user },
//                             })
//                           }>
//                           {user?.email}
//                         </td>
//                         <td className="px-2 py-1 sm:px-4 md:px-6 text-center"
//                          onClick={() =>
//                             navigate(`/gutdiagnosis/${user._id}`, {
//                               state: { patient: user },
//                             })
//                           }>
//                           {user?.phone}
//                         </td>
//                         <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center"
//                          onClick={() =>
//                             navigate(`/gutdiagnosis/${user._id}`, {
//                               state: { patient: user },
//                             })
//                           }>
//                           {FormateDate(user?.createdAt)}
//                         </td>
//                         <td className="px-2 py-1 sm:px-4 md:px-6 text-center capitalize"
//                          onClick={() =>
//                             navigate(`/gutdiagnosis/${user._id}`, {
//                               state: { patient: user },
//                             })
//                           }>
//                           {user?.bst}
//                         </td>
//                         <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center"
//                          onClick={() =>
//                             navigate(`/gutdiagnosis/${user._id}`, {
//                               state: { patient: user },
//                             })
//                           }>
//                           {user?.bmi?.range}
//                         </td>
//                         <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center"
//                          onClick={() =>
//                             navigate(`/gutdiagnosis/${user._id}`, {
//                               state: { patient: user },
//                             })
//                           }>
//                           <span
//                             className={
//                               user?.orderDetails?.purchase
//                                 ? "text-green-500"
//                                 : "text-red-500"
//                             }
//                           >
//                             {user?.orderDetails?.purchase ? "Yes" : "No"}
//                           </span>
//                         </td>
//                         <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
//                           <button
//                             onClick={() =>
//                               navigate(`/gutdiagnosis/${user._id}`, {
//                                 state: { patient: user },
//                               })
//                             }
//                             className="text-black"
//                           >
//                             <FaPencilAlt />
//                           </button>
//                           <button
//                             onClick={() => handleDelete(user._id)}
//                             className="hover:text-red-600 ml-3"
//                           >
//                             <MdDelete />
//                           </button>
//                         </td>
//                       </tr>
//                     )
//                   )
//                 )
//               : [...Array(5)].map((_, key) => (
//                   <tr key={key}>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer data="loading..." />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                     <td className="px-4 py-2 sm:py-3 sm:p-3">
//                       <SkeletonContainer
//                         data="loading..."
//                         className="w-4/5 ml-2"
//                       />
//                     </td>
//                   </tr>
//                 ))}
//           </tbody>
//         </table>
//       </div>

//       <div className=" px-10 fixed bottom-0 flex justify-center md:left-7 left-0 bg-white w-full h-16">
//         <div className="flex justify-center items-center my-4">
//           <button
//             className="px-6 py-2 mx-1 text-sm md:text-base rounded-lg text-white bg-green-900 font-semibold"
//             onClick={handlePrevPage}
//             disabled={currentPage === 1}
//           >
//             Previous
//           </button>
//           <span className="px-4 text-green-900 font-bold whitespace-nowrap">{`${currentPage} / ${totalPages}`}</span>
//           <button
//             className="px-6 py-2 mx-1 text-sm md:text-base  rounded-lg text-white bg-green-900  font-semibold"
//             onClick={handleNextPage}
//             disabled={currentPage === totalPages}
//           >
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Gut;
import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { apiurl } from "../config/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/slices/diagnosPatient";
import SkeletonContainer from "../routes/skeleton";
import { useFormatOnlyDate } from "../hooks/useFormatDate";
import moment from "moment";
import { GoDownload } from "react-icons/go";
import ExportDataHandler from "../components/exportDataHandler";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "./confirmModal";
import { IoSearchOutline } from "react-icons/io5";

const Gut = () => {
  const [gutsData, setGutsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState({ from: "", till: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("50");
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${apiurl}/api/v1/gut/getall?page=${currentPage}&limit=${itemsPerPage}`,
          headers: {},
        };
        const response = await axios.request(config);
        if (response.data.success) {
          const data = response.data.data || [];
          setGutsData(data);
          setFilteredData(data);
          setTotalPages(response.data.pagination.totalPages);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    return `${year}-${padZero(month)}-${padZero(day)}`;
  };

  const FormateDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };

  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  useEffect(() => {
    if (date.from && date.till) {
      FilterHandler();
    }
  }, [date]);

  const FilterHandler = () => {
    if (gutsData.length > 0) {
      const fromDate = new Date(date.from);
      const tillDate = new Date(date.till);

      const filtered = gutsData.filter((item) => {
        const gutsDate = new Date(
          moment(item?.diagonse_date).format("YYYY-MM-DD")
        );
        return gutsDate >= fromDate && gutsDate <= tillDate;
      });

      setFilteredData(filtered);
      setCurrentPage(1);
    }
  };

  
  const confirmBulkDelete = async () => {
    if (selectedRows.length > 0) {
      try {
        const config = {
          method: "delete",
          url: "https://api.thehealthspanco.com/api/v1/gut/deleteall",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            gutsIds: selectedRows,
          },
        };

        const response = await axios.request(config);
        if (response.data.success) {
          setGutsData((prevData) =>
            prevData.filter((item) => !selectedRows.includes(item._id))
          );
          setFilteredData((prevData) =>
            prevData.filter((item) => !selectedRows.includes(item._id))
          );
          setSelectedRows([]);
          setShowModal(false);
        }
      } catch (error) {
        console.error("Error during bulk delete:", error);
        setShowModal(false);
      }
    }
  };

 
  const confirmDelete = async () => {
    if (userIdToDelete) {
      try {
        const config = {
          method: "delete",
          url: `https://api.thehealthspanco.com/api/v1/gut/delete/${userIdToDelete}`,
          headers: {},
        };

        const response = await axios.request(config);
        if (response.data.success) {
          setFilteredData((prevData) =>
            prevData.filter((user) => user._id !== userIdToDelete)
          );
          setShowModal(false);
        }
      } catch (error) {
        console.error("Error deleting the user:", error);
        setShowModal(false);
      }
    }
  };

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      setUserIdToDelete(null); 
      setShowModal(true);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setUserIdToDelete(null);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    return payload.map((item) => ({
      _id: item._id,
      name: item.name,
      email: item.email,
      phone: item.phone,
      date: item.date,
      age: item.age,
      gender: item.gender,
      weight: item.weight,
      height: item.height,
      health_goals: Array.isArray(item.health_goals)
        ? item.health_goals.join(", ")
        : "",
      bmi_category: item.bmi?.category || "",
      bmi_range: item.bmi?.range || "",
      gut_symptom: Array.isArray(item.gut_symptom)
        ? item.gut_symptom.join(", ")
        : "",
      exercise: item.exercise || "",
      phyiscal_activity: item.phyiscal_activity || "",
      medical_condition: item.medical_condition || "",
      root_cause: Array.isArray(item.root_cause)
        ? item.root_cause.join(", ")
        : "",
      bmr: item.bmr || "",
      idealWeight: item.idealWeight || "",
      weightToLose: item.weightToLose || "",
      weightLossDuration: item.weightLossDuration || "",
      idealWaistSize: item.idealWaistSize || "",
      dailyCalorieIntake: item.dailyCalorieIntake || "",
      diagnosed_conditions: Array.isArray(item.diagnosed_conditions)
        ? item.diagnosed_conditions.join(", ")
        : "",
      eating_habits: item.eating_habits || "",
      allergic: Array.isArray(item.allergic) ? item.allergic.join(", ") : "",
      medication: item.medication || "",
      sleep: item.sleep || "",
      diagonse_date: item.diagonse_date || "",
      description: item.description || "",
      createdAt: item.createdAt || "",
      updatedAt: item.updatedAt || "",
    }));
  };

  return (
    <div className="w-full h-full">
      <div className="my-3 flex flex-col md:flex-row items-center relative gap-3">
        {/* Delete Button */}
        {selectedRows.length > 0 && (
          <div className="flex items-center w-full sm:w-auto mt-0 md:mt-3">
            <button
              className="text-white bg-green-800 px-4 py-1 rounded cursor-pointer w-full sm:w-auto"
              onClick={handleDeleteSelected}
            >
              Delete 
            </button>
          </div>
        )}

        {/* Confirmation Modal */}
        {showModal && (
          <ConfirmModal
            message={
              userIdToDelete
                ? "Are you sure you want to delete this user?"
                : `Are you sure you want to delete ${selectedRows.length} user(s)?`
            }
            onConfirm={userIdToDelete ? confirmDelete : confirmBulkDelete}
            onCancel={cancelDelete}
          />
        )}

        {/* Search & Filters */}
        <div className="w-full flex flex-col md:flex-row justify-end items-center gap-3 mt-3">
          <div className="flex flex-col sm:flex-row md:items-center gap-3 w-full md:w-auto">
            <input
              placeholder="From"
              name="from"
              type="date"
              max={getCurrentDate()}
              className="outline-none border border-green-800 rounded-lg text-sm px-3 py-2 w-full sm:w-auto"
              onChange={dateHandler}
              value={date.from}
            />
            <input
              placeholder="Till"
              name="till"
              type="date"
              min={date.from}
              max={getCurrentDate()}
              className="outline-none border border-green-800 rounded-lg text-sm px-3 py-2 w-full sm:w-auto"
              onChange={dateHandler}
              value={date.till}
            />
            <button
              className={`border p-2 px-4 text-center rounded-md w-full sm:w-auto ${
                date.from && date.till && filteredData.length > 0
                  ? "bg-green-50 border-green-800 text-green-800"
                  : "border-green-800 text-green-800"
              }`}
              onClick={() => ExportDataHandler(ExportPayload(filteredData))}
              disabled={!(date.from && date.till && filteredData.length > 0)}
            >
              <GoDownload className="text-xl" />
            </button>
          </div>
        </div>
      </div>

      <div className="h-full overflow-scroll text-xs md:text-sm lg:text-base mt-6 pb-56 md:pb-48 lg:pb-20">
        <table className="border-collapse w-full relative table-auto overflow-auto border">
          <thead className="bg-green-900 border-y text-white lg:text-base text-xs top-0 z-50">
            <tr>
              <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Select</th>
              <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Name</th>
              <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Email</th>
              <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Phone</th>
              <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Date</th>
              <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">B.S.T</th>
              <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">B.M.I</th>
              <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Purchase</th>
              <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((user) => (
                <tr key={user._id}>
                  <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                    <input
                      type="checkbox"
                      className="scale-125"
                      checked={selectedRows.includes(user._id)}
                      onChange={() => handleSelectRow(user._id)}
                    />
                  </td>
                  <td
                    className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer capitalize"
                    onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                  >
                    {user?.name}
                  </td>
                  <td
                    className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer capitalize"
                    onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                  >
                    {user?.email}
                  </td>
                  <td
                    className="px-2 py-1 sm:px-4 md:px-6 text-center"
                    onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                  >
                    {user?.phone}
                  </td>
                  <td
                    className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center"
                    onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                  >
                    {FormateDate(user?.createdAt)}
                  </td>
                  <td
                    className="px-2 py-1 sm:px-4 md:px-6 text-center capitalize"
                    onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                  >
                    {user?.bst}
                  </td>
                  <td
                    className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center"
                    onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                  >
                    {user?.bmi?.range}
                  </td>
                  <td
                    className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center"
                    onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                  >
                    <span className={user?.orderDetails?.purchase ? "text-green-500" : "text-red-500"}>
                      {user?.orderDetails?.purchase ? "Yes" : "No"}
                    </span>
                  </td>
                  <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                    <button
                      onClick={() => navigate(`/gutdiagnosis/${user._id}`, { state: { patient: user } })}
                      className="text-black"
                    >
                      <FaPencilAlt />
                    </button>
                    <button onClick={() => handleDelete(user._id)} className="hover:text-red-600 ml-3">
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              [...Array(5)].map((_, key) => (
                <tr key={key}>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3"><SkeletonContainer data="loading..." className="w-4/5 ml-2" /></td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="px-10 fixed bottom-0 flex justify-center md:left-7 left-0 bg-white w-full h-16">
        <div className="flex justify-center items-center my-4">
          <button
            className="px-6 py-2 mx-1 text-sm md:text-base rounded-lg text-white bg-green-900 font-semibold"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="px-4 text-green-900 font-bold whitespace-nowrap">{`${currentPage} / ${totalPages}`}</span>
          <button
            className="px-6 py-2 mx-1 text-sm md:text-base rounded-lg text-white bg-green-900 font-semibold"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gut;