import React, { useState } from "react";
import Gut from "./gut";
import GutSession from "./gutSession";
import Layout from "../components/layout";

const GutQuiz = () => {
  const [activeRoute, setActiveRoute] = useState("diagnosis");
  return (
    <Layout>
      <div className="h-screen w-full p-6">
        <button className={`px-4 py-2 mx-2 font-semibold ${ activeRoute === "diagnosis" ? 'bg-green-900 text-white' : 'border border-green-900 text-green-900'}  rounded" } hover:bg-green-900 hover:text-white rounded`} 
        onClick={() => setActiveRoute("diagnosis")} 
        >
          Diagnosis
        </button>
        <button
          className={`px-4 py-2 font-semibold mx-2 ${
            activeRoute === "session" ? 'bg-green-900 text-white' : 'border border-green-900 text-green-900'}  rounded`}
          onClick={() => setActiveRoute("session")}
        >
          Session
        </button>
        <div className=" flex-1 overflow-y-auto max-h-[500px] ">
          {activeRoute === "diagnosis" && <Gut />}
          {activeRoute === "session" && <GutSession />}
        </div>
      </div>
    </Layout>
  );
};

export default GutQuiz;
